import React from "react";

const NotFoundPage = () => (
  <React.Fragment>
    <section className="py-6">
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center">
            <span className="display-1 d-block">404</span>
            <div className="mb-4 lead">
              The page you are looking for was not found.
            </div>
            <a href="/" className="btn btn-link text-primary">
              Back to Home
            </a>
          </div>
        </div>
      </div>
    </section>
  </React.Fragment>
);

export default NotFoundPage;
